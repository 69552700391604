@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.iti{width:100%}.iti__flag{background-image:url(/static/media/flags.007b2705.png)}@media(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi){.iti__flag{background-image:url(/static/media/flags@2x.9d5328fb.png)}}
* {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

p {
  line-height: 1.5;
}

html {
  height: 100vh;
}

h1 {
  color: unset;
}
h2 {
  color: unset;
}

body,
#root {
  height: 100%;
}

*:focus {
  outline: none;
}

*:active {
  outline: none;
}

.react-tabs__tab:focus{box-shadow:none;outline:none}.react-tabs__tab--selected{color:#000}.react-tabs__tab:focus,.react-tabs__tab--selected{border-color:silver}.react-tabs__tab::after{display:none}.ant-picker-time-panel-column{overflow-y:scroll}.ant-picker-now{display:none !important}.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{background:#f3ebff !important}.ant-btn-primary{background:#933697;color:#fff;border-color:#933697}.ant-btn-primary:hover{background:#933697;color:#fff;border-color:#933697}
