.react-tabs__tab {
  &:focus {
    box-shadow: none;
    outline: none;
  }

  &--selected {
    color: black;
  }

  &:focus, &--selected {
    border-color: #C0C0C0;
  }

  &::after {
    display: none;
  }
}
