@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

p {
  line-height: 1.5;
}

html {
  height: 100vh;
}

h1 {
  color: unset;
}
h2 {
  color: unset;
}

body,
#root {
  height: 100%;
}

*:focus {
  outline: none;
}

*:active {
  outline: none;
}
